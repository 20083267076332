/* adjusting the built-in theme */

/* calendar event */
.calendar_default_event_inner {
    background: #2e78d6;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 10pt;
    padding: 5px;
    opacity: 0.8;
}
